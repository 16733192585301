.live-label {
    position: absolute;
    top: 10px;
    left: 20px;
    background-color: red;
    color: white;
    padding: 1px 5px;
}

.username {
    position: absolute;
    bottom: 10px;
    left: 20px;
    background-color: black;
}

.username a {
    font-size: 18px;
    color: white;
    text-decoration: none;
    padding: 2px 8px;
}