.boxDiv div {
    margin: 20px auto;
    width: 30%;
    /* border: 1px solid; */
    padding: 10px;
    text-align: center;
    background-color: #790e8b;
    border-radius: 8px;
}

.boxDiv div a {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
}