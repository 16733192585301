#video {
    height: 450px;
    width: 60%;
    margin: 10% auto;
}

.go-live {
    width: 20%;
    /* border: 1px solid; */
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.go-live button{
    height: 60px;
    width: 150px;
    border: 0;
    background-color: #a30000;
    font-size: 15px;
    font-weight: 800;
    border-radius: 10px;
    color:#FFFFFF;
}